var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CModal',{attrs:{"title":_vm.shop.Id ? 'Cập nhật Cửa hàng' : 'Thêm Cửa hàng',"show":_vm.detailModal,"closeOnBackdrop":false,"centered":"","size":"lg"},on:{"update:show":function($event){_vm.detailModal=$event}},scopedSlots:_vm._u([{key:"body-wrapper",fn:function(){return [_c('div',{staticClass:"modal-body overflow-auto",staticStyle:{"max-height":"calc(100vh - 11.6rem)"}},[_c('CRow',[_c('CCol',{attrs:{"md":"6"}},[_c('CRow',[_c('CCol',{attrs:{"md":"6"}},[_c('CInput',{attrs:{"label":"Tên Cửa hàng*","placeholder":"Tên Cửa hàng","add-input-classes":{
                  'is-invalid': !!_vm.inValidObject['shop.Name'],
                },"invalid-feedback":_vm.inValidObject['shop.Name']},model:{value:(_vm.shop.Name),callback:function ($$v) {_vm.$set(_vm.shop, "Name", $$v)},expression:"shop.Name"}})],1),_c('CCol',{attrs:{"md":"6"}},[(_vm.shop.Id)?_c('CInput',{attrs:{"label":"Alias*","placeholder":"Alias","value":_vm.shop.Alias,"add-input-classes":{
                  'is-invalid': !!_vm.inValidObject['shop.Alias'],
                },"invalid-feedback":_vm.inValidObject['shop.Alias'],"disabled":""}}):_c('CInput',{attrs:{"label":"Alias*","placeholder":"Alias","add-input-classes":{
                  'is-invalid': !!_vm.inValidObject['shop.Alias'],
                },"invalid-feedback":_vm.inValidObject['shop.Alias']},model:{value:(_vm.shop.Alias),callback:function ($$v) {_vm.$set(_vm.shop, "Alias", $$v)},expression:"shop.Alias"}})],1),_c('CCol',{attrs:{"md":"12"}},[_c('CTextarea',{attrs:{"label":"Mô tả","placeholder":"Mô tả Cửa hàng","add-input-classes":{
                  'is-invalid': !!_vm.inValidObject['shop.Description'],
                },"invalid-feedback":_vm.inValidObject['shop.Description']},model:{value:(_vm.shop.Description),callback:function ($$v) {_vm.$set(_vm.shop, "Description", $$v)},expression:"shop.Description"}})],1),_c('CCol',{attrs:{"md":"12"}},[_c('CTextarea',{attrs:{"label":"Địa chỉ","placeholder":"Địa chỉ","add-input-classes":{
                  'is-invalid': !!_vm.inValidObject['shop.Address'],
                },"invalid-feedback":_vm.inValidObject['shop.Address']},model:{value:(_vm.shop.Address),callback:function ($$v) {_vm.$set(_vm.shop, "Address", $$v)},expression:"shop.Address"}})],1),_c('CCol',{attrs:{"md":"12"}},[_c('CSelect',{attrs:{"label":"Trạng thái","placeholder":"Chọn Trạng thái","value":_vm.shop.Status,"options":Object.keys(_vm.$const.STATUS_TEXT).map(function (_) {
                    return {
                      value: _,
                      label: _vm.$const.STATUS_TEXT[_],
                    };
                  }),"add-input-classes":{
                  'is-invalid': !!_vm.inValidObject['shop.Status'],
                },"invalid-feedback":_vm.inValidObject['shop.Status']},on:{"update:value":function($event){return _vm.$set(_vm.shop, "Status", $event)}}})],1)],1)],1),_c('CCol',{attrs:{"md":"6"}},[_c('CCard',[_c('CCardHeader',[_c('CIcon',{attrs:{"name":"cil-qr-code"}}),_vm._v(" Mã QR cửa hàng ")],1),_c('CCardBody',[_c('div',{staticClass:"text-center"},[(_vm.qrLink)?_c('div',{attrs:{"id":"qr-container"}},[_c('qrcode-vue',{attrs:{"value":_vm.qrLink,"size":"250","level":"H","render-as":"svg"}}),_c('h4',{staticClass:"mt-2"},[_c('a',{attrs:{"target":"blank","href":_vm.qrLink}},[_vm._v(_vm._s(_vm.qrLink))])])],1):_c('div',[_vm._v(" Chưa tạo được QR. Hãy cung cấp Alias cửa hàng! ")]),(_vm.qrLink)?_c('CButton',{staticClass:"btn btn-success mt-2",on:{"click":function($event){return _vm.printElem('qr-container', _vm.shop)}}},[_vm._v(" In Mã QR ")]):_vm._e()],1)])],1)],1)],1)],1)]},proxy:true},{key:"footer",fn:function(){return [_c('CButton',{staticClass:"btn btn-primary",attrs:{"disabled":_vm.saving},on:{"click":_vm.saveShop}},[_c('CIcon',{attrs:{"name":"cil-save"}}),_vm._v(" Lưu thông tin ")],1),(_vm.saving)?_c('div',{staticClass:"d-inline-block font-italic"},[_c('CSpinner',{staticClass:"ml-2",attrs:{"color":"info","size":"sm"}}),_vm._v(" Đang xử lý... ")],1):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }