<template>
  <div>
    <CCard>
      <CCardHeader>
        <span class="font-weight-bold">
          <CIcon name="cilStar" />
          DS Cửa hàng
        </span>
        <CButton
          color="success"
          size="sm"
          class="float-right"
          @click.prevent="
            (e) => {
              editing = true;
              shopId = 0;
            }
          "
        >
          <CIcon name="cil-plus" />
          Thêm Cửa hàng
        </CButton>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol md="4">
            <CSelect
              label="Trạng thái"
              placeholder="Trạng thái"
              :value.sync="shopStatus"
              :options="[
                { value: null, label: 'Tất cả' },
                ...Object.keys(statusList).map((_) => {
                  return {
                    value: _,
                    label: statusList[_],
                  };
                }),
              ]"
              :horizontal="{
                label: 'col-4 text-right',
                input: 'col-8',
              }"
            />
          </CCol>
          <CCol md="4">
            <CInput
              label="Tên Cửa hàng"
              placeholder="Tên Cửa hàng"
              v-model="shopName"
              :horizontal="{
                label: 'col-4 text-right',
                input: 'col-8',
              }"
              @keyup.enter="doFilter"
            />
          </CCol>
          <CCol md="3" class="text-center">
            <CButton color="primary" class="mr-2 mb-3" @click="doFilter">
              <CIcon name="cil-search" custom-classes="c-icon m-0" />
              Tìm kiếm
            </CButton>

            <CButton color="secondary" class="mb-3" @click="clear">
              <CIcon name="cil-x-circle" custom-classes="c-icon m-0" />
              Đặt lại
            </CButton>
          </CCol>
        </CRow>
        <odata-table
          ref="lstShop"
          url="odata/Shop"
          :filter="filter"
          select="Id,Name,Alias,Description,Address,Status,CreatedAt"
          sortBy="CreatedAt desc"
          :pageSize="pageSize"
          :colSetting="{
            Action: {
              display: '#',
              sortable: false,
              style: 'min-width: 50px;',
            },
            Name: {
              field: 'Name',
              display: 'Tên Cửa hàng',
              sortable: true,
              style: 'min-width: 120px',
            },
            Alias: {
              field: 'Alias',
              display: 'Alias',
              sortable: true,
              style: 'min-width: 100px',
            },
            Description: {
              field: 'Description',
              display: 'Mô tả',
              sortable: true,
              style: 'min-width: 120px',
            },
            Address: {
              field: 'Address',
              display: 'Địa chỉ',
              sortable: true,
              style: 'min-width: 120px',
            },
            Status: {
              field: 'Status',
              display: 'Trạng thái',
              sortable: true,
              style: 'min-width: 100px',
            },
            CreatedAt: {
              field: 'CreatedAt',
              display: 'Ngày tạo',
              sortable: true,
              style: 'min-width: 120px',
            },
          }"
        >
          <template v-slot:tbody="{ rows }">
            <tr v-for="(row, i) in rows" :key="`tr-${i}`">
              <td>{{ i + 1 + ($refs.lstShop.page - 1) * pageSize }}.</td>
              <td>
                <a
                  href="javascript:"
                  class="text-primary"
                  :title="`Cập nhật: ${row.Name}`"
                  @click="
                    (e) => {
                      shopId = row.Id;
                      editing = true;
                    }
                  "
                >
                  <CIcon name="cil-pencil" />
                  {{ row.Name }}
                </a>
              </td>
              <td>{{ row.Alias }}</td>
              <td>
                {{ row.Description }}
              </td>
              <td>
                {{ row.Address }}
              </td>
              <td>
                <CBadge :color="getBadge(row.Status)">
                  {{ $const.STATUS_TEXT[row.Status] }}
                </CBadge>
              </td>
              <td>
                {{
                  $moment
                    .utc(row.CreatedAt)
                    .utcOffset("-08:00")
                    .format("DD/MM/YY HH:mm")
                }}
              </td>
            </tr>
          </template>
        </odata-table>
      </CCardBody>
    </CCard>
    <detail
      :editing.sync="editing"
      :shopId.sync="shopId"
      @reload="$refs.lstShop.loadData()"
    />
  </div>
</template>

<script>
import odataTable from "@/components/odataTable.vue";
import detail from "./Detail.vue";

export default {
  name: "Shop",
  components: {
    odataTable,
    detail,
  },
  data() {
    return {
      editing: false,
      shopId: 0,
      pageSize: 10,
      shopStatus: null,
      statusList: this.$const.STATUS_TEXT,
      shopName: null,
      filters: null,
    };
  },
  computed: {
    filter() {
      let filters = [];

      if (this.filters && this.filters.length) {
        filters = [...this.filters];
      }

      return filters.join(" and ");
    },
  },
  methods: {
    getBadge(status) {
      switch (status) {
        case this.$const.STATUS.Active:
          return "success";
        case this.$const.STATUS.InActive:
          return "secondary";
        default:
          "primary";
      }
    },
    doFilter() {
      let filters = [];
      this.shopStatus && filters.push(`Status eq ${this.shopStatus}`);
      this.shopName && filters.push(`contains(Name,'${this.shopName}')`);
      filters.join(" and ");

      if (JSON.stringify(this.filters) != JSON.stringify(filters)) {
        this.filters = filters;
      } else {
        this.$refs.lstShop.loadData();
      }
    },
    clear() {
      this.shopStatus = this.$const.STATUS.Active.toString();
      this.shopName = null;
    },
  },
};
</script>
