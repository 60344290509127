<template>
  <CModal
    :title="shop.Id ? 'Cập nhật Cửa hàng' : 'Thêm Cửa hàng'"
    :show.sync="detailModal"
    :closeOnBackdrop="false"
    centered
    size="lg"
  >
    <template v-slot:body-wrapper>
      <div
        class="modal-body overflow-auto"
        style="max-height: calc(100vh - 11.6rem);"
      >
        <CRow>
          <CCol md="6">
            <CRow>
              <CCol md="6">
                <CInput
                  label="Tên Cửa hàng*"
                  placeholder="Tên Cửa hàng"
                  v-model="shop.Name"
                  :add-input-classes="{
                    'is-invalid': !!inValidObject['shop.Name'],
                  }"
                  :invalid-feedback="inValidObject['shop.Name']"
                />
              </CCol>

              <CCol md="6">
                <CInput
                  v-if="shop.Id"
                  label="Alias*"
                  placeholder="Alias"
                  :value="shop.Alias"
                  :add-input-classes="{
                    'is-invalid': !!inValidObject['shop.Alias'],
                  }"
                  :invalid-feedback="inValidObject['shop.Alias']"
                  disabled
                />
                <CInput
                  v-else
                  label="Alias*"
                  placeholder="Alias"
                  v-model="shop.Alias"
                  :add-input-classes="{
                    'is-invalid': !!inValidObject['shop.Alias'],
                  }"
                  :invalid-feedback="inValidObject['shop.Alias']"
                />
              </CCol>

              <CCol md="12">
                <CTextarea
                  label="Mô tả"
                  placeholder="Mô tả Cửa hàng"
                  v-model="shop.Description"
                  :add-input-classes="{
                    'is-invalid': !!inValidObject['shop.Description'],
                  }"
                  :invalid-feedback="inValidObject['shop.Description']"
                />
              </CCol>

              <CCol md="12">
                <CTextarea
                  label="Địa chỉ"
                  placeholder="Địa chỉ"
                  v-model="shop.Address"
                  :add-input-classes="{
                    'is-invalid': !!inValidObject['shop.Address'],
                  }"
                  :invalid-feedback="inValidObject['shop.Address']"
                />
              </CCol>

              <CCol md="12">
                <CSelect
                  label="Trạng thái"
                  placeholder="Chọn Trạng thái"
                  :value.sync="shop.Status"
                  :options="
                    Object.keys($const.STATUS_TEXT).map((_) => {
                      return {
                        value: _,
                        label: $const.STATUS_TEXT[_],
                      };
                    })
                  "
                  :add-input-classes="{
                    'is-invalid': !!inValidObject['shop.Status'],
                  }"
                  :invalid-feedback="inValidObject['shop.Status']"
                />
              </CCol>
            </CRow>
          </CCol>
          <CCol md="6">
            <CCard>
              <CCardHeader>
                <CIcon name="cil-qr-code" />
                Mã QR cửa hàng
              </CCardHeader>
              <CCardBody>
                <div class="text-center">
                  <div v-if="qrLink" id="qr-container">
                    <qrcode-vue
                      :value="qrLink"
                      size="250"
                      level="H"
                      render-as="svg"
                    ></qrcode-vue>
                    <h4 class="mt-2">
                      <a target="blank" :href="qrLink">{{ qrLink }}</a>
                    </h4>
                  </div>
                  <div v-else>
                    Chưa tạo được QR. Hãy cung cấp Alias cửa hàng!
                  </div>
                  <CButton
                    class="btn btn-success mt-2"
                    v-if="qrLink"
                    @click="printElem('qr-container', shop)"
                  >
                    In Mã QR
                  </CButton>
                </div>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </div>
    </template>
    <template v-slot:footer>
      <CButton class="btn btn-primary" @click="saveShop" :disabled="saving">
        <CIcon name="cil-save" />
        Lưu thông tin
      </CButton>
      <div class="d-inline-block font-italic" v-if="saving">
        <CSpinner color="info" size="sm" class="ml-2" />
        Đang xử lý...
      </div>
    </template>
  </CModal>
</template>

<script>
import QrcodeVue from "qrcode.vue";
export default {
  props: ["editing", "shopId"],
  components: {
    QrcodeVue,
  },
  data() {
    return {
      detailModal: false,
      inValidObject: {},
      shop: this.newShop(),
      saving: false,
    };
  },

  computed: {
    qrLink() {
      let link = "";
      let alias = this.shop.Alias;
      if (alias) {
        return `${location.origin}/lucky/${alias}`;
      }

      return link;
    },
  },

  watch: {
    async shopId(val) {
      await this.loadShopInfo(val);
    },
    editing(val) {
      this.detailModal = val;
      if (!val) {
        this.$emit("update:shopId", null);
      }
    },
    detailModal(val) {
      this.$emit("update:editing", val);
    },
  },

  methods: {
    newShop() {
      return {
        Id: 0,
        Name: null,
        Alias: null,
        Address: null,
        Description: null,
        Status: this.$const.STATUS.Active.toString(),
      };
    },

    async loadShopInfo(shopId) {
      try {
        this.inValidObject = {};
        if (shopId) {
          let filters = [];
          shopId && filters.push(`Id eq ${shopId}`);
          let resp = await this.$http.get(`odata/Shop`, {
            params: {
              $top: 1,
              $filter: filters.join(" and "),
              $select: "Id,Name,Alias,Address,Description,Status",
            },
          });
          if (resp && resp.status == 200) {
            let _ = resp.data.value[0];
            this.shop = {
              Id: _.Id,
              Name: _.Name,
              Alias: _.Alias,
              Address: _.Address,
              Description: _.Description,
              Status: _.Status.toString(),
            };
          }
        } else {
          this.shop = this.newShop();
        }
      } catch (error) {
        alert(error);
      }
    },

    async saveShop() {
      let shop = this.shop;
      this.inValidObject = this.checkValid(shop);
      if (Object.keys(this.inValidObject).length) {
        alert("Thông tin nhập chưa đúng, vui lòng kiểm tra lại!");
        return;
      }

      this.saving = true;
      try {
        let shopData = {
          Name: shop.Name,
          Address: shop.Address,
          Description: shop.Description,
          Status: parseInt(shop.Status),
        };
        // Customer
        if (!shop.Id) {
          // post
          shopData.Alias = shop.Alias;
          let resp = await this.$http.post(`odata/Shop`, shopData);
          if (resp && resp.status == 201) {
            this.detailModal = false;
            this.$emit("reload");
          }
        } else {
          // patch
          let resp = await this.$http.patch(`odata/Shop/${shop.Id}`, shopData);
          if (resp && resp.status == 204) {
            this.detailModal = false;
            this.$emit("reload");
          }
        }
      } catch (error) {
        if (error?.response?.data?.error?.message) {
          alert(this.$const.MESSAGE[error.response.data.error.message]);
        } else {
          alert(error);
        }
      }
      this.saving = false;
    },

    checkValid(shop) {
      let inValidObject = {};

      if (!shop.Name)
        inValidObject["shop.Name"] = "Vui lòng nhập Tên cửa hàng!";
      if (!shop.Alias) inValidObject["shop.Alias"] = "Vui lòng nhập Alias!";
      if (!shop.Status)
        inValidObject["shop.Status"] = "Vui lòng chọn Trạng Thái!";

      return inValidObject;
    },

    printElem(elem, shop) {
      // Get HTML to print from element
      const prtHtml = document.getElementById(elem).innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Heading
      let heading = "";
      if (shop && shop.Name) {
        heading = `<h1>${shop.Name}</h1>`;
      }

      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
          ${stylesHtml}
        </head>
        <body class="text-center">
          ${heading}
          ${prtHtml}
        </body>
      </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
  },
};
</script>
